import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth/auth.service';
import { NavbarService } from '../navbar.service';
import { BackendThemeOptions } from '../../backend-theme-options';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  url?: string;
  constructor(public auth: AuthService, public router: Router, public nav: NavbarService, private route: ActivatedRoute) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.data && route.data.parent && route.parent && route.parent.routeConfig) {
      return true;
    } else {
      this.router.navigate(["/404"]);
      this.nav.visible = false;
      this.nav.routerArray = [];
      return false;
    }
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.url = state.url;
    if (!this.auth.isUserAuthenticated()) {
      this.nav.hide();
      if (route.queryParams !== undefined && route.queryParams.r) {
        this.url = this.url.replace('?r=true', '');
        localStorage.setItem('redirect_url', this.url);
      }
      this.router.navigate(['/login']);
      return false;
    } else {
      this.nav.show();
    }
    this.nav.show();
    return true;
  }
}
