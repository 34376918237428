import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { PaginationModel, RequestParamModel } from '../models';
import { BaseService } from '../services';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BigPostService {
  public API_COMPANY = 'big-post-company';
  public API_BIG_POST_ITEM_TYPE = 'big-post-item-type';
  public API_BIG_POST_ITEM = 'big-post-item';
  public API_BIG_POST_JOB_ITEM = 'big-post-job-item';
  public API_BIG_POST = 'big-post-job';
  constructor(
    public baseService: BaseService,
    public httpClient: HttpClient
  ) { }

  postBigPostItem(body: any) {
    return this.baseService.post(this.API_BIG_POST_JOB_ITEM, body).pipe(
      map<any, any>(response => {
        return response
         }),
        catchError(err => throwError(() => err))
    );
  }

  postBigPost(body: any) {
    return this.baseService.post(this.API_BIG_POST, body).pipe(
      map<any, any>(response => {
        return response
        }),
        catchError(err => throwError(() => err))
    );
  }

  // Get Lists
  getCompanyList(requestParamModel?: RequestParamModel): any {
    return this.baseService.get(this.API_COMPANY, requestParamModel)
      .pipe(map<any, any>(response => {
        const companyList = new PaginationModel<any>();
        const headers = response.headers;
        companyList.setHeaders(headers);
        companyList.data = response.body.data as any[];
        return companyList;
        }),
      catchError(err => throwError(() => err))
  );
  }

  postCompany(body: any) {
    return this.baseService.post(this.API_COMPANY, body).pipe(
      map<any, any>(response => {
        return response;
       }),
        catchError(err => throwError(() => err))
    );
  }

  getItemTypeList(requestParamModel?: RequestParamModel): any {
    return this.baseService.get(this.API_BIG_POST_ITEM_TYPE, requestParamModel)
      .pipe(map<any, any>(response => {
        const ItemTypeList = new PaginationModel<any>();
        const headers = response.headers;
        ItemTypeList.setHeaders(headers);
        ItemTypeList.data = response.body.data as any[];
        return ItemTypeList;
        }),
      catchError(err => throwError(() => err))
  );
  }

  postItemType(body: any) {
    return this.baseService.post(this.API_BIG_POST_ITEM_TYPE, body).pipe(
      map<any, any>(response => {
        return response.body;

        }),
        catchError(err => throwError(() => err))
    );
  }

  getItemList(requestParamModel?: RequestParamModel): any {
    return this.baseService.get(this.API_BIG_POST_ITEM, requestParamModel)
      .pipe(map<any, any>(response => {
        const itemList = new PaginationModel<any>();
        const headers = response.headers;
        itemList.setHeaders(headers);
        itemList.data = response.body.data as any[];
        return itemList;
        }),
      catchError(err => throwError(() => err))
  );
  }

  postItem(body: any) {
    return this.baseService.post(this.API_BIG_POST_ITEM, body).pipe(
      map<any, any>(response => {
        return response;

        }),
        catchError(err => throwError(() => err))
    );
  }

  getSuburb(q: string = 'A') {
    return this.baseService.get(`big-post-job/suburbs?filter[search_keyword]=${q}`).pipe(
      map(response => {
        return response;
        }),
        catchError(err => throwError(() => err))
    );
  }

  pushBigPost(body: any) {
    return this.baseService.post(`quote-order/push-at-big-post`, body).pipe(
      map<any, any>(response => {
        return response;
        }),
        catchError(err => throwError(() => err))
    );
  }

}
