import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountModel, ActivityModel, RequestParamModel, ResponseModel, UserModel } from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {


  constructor(private baseService: BaseService) { }

  // Get Lists
  getLists(requestParamModel: RequestParamModel): any {
    return this.baseService.get('activity', requestParamModel)
      .pipe(map<any, any>(response => {
        const responseList = new PaginationModel<ActivityModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        responseList.data = response.body.data as ActivityModel[];
        return responseList;
           }),
        catchError(err => throwError(() => err))
    );
  }

  // get details.
  getDetails(categoryID: number, requestParamModel?: RequestParamModel): Observable<ActivityModel> {
    return this.baseService.get('activity/' + categoryID, requestParamModel)
      .pipe(map<any, ActivityModel>(response => {
        return response.body.data as ActivityModel;
            }),
        catchError(err => throwError(() => err))
    );
  }

}
