import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GatherCommitment, GatherCreditCompany, PaginationModel, RequestParamModel } from '../models';
import { BaseService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class GatherCommitmentService {

  constructor(private baseService: BaseService) { }

  getCreditTransaction(requestParamModel: RequestParamModel): Observable<PaginationModel<GatherCommitment>> {
    return this.baseService.get('gatherco-credit-transaction', requestParamModel)
      .pipe(map<any, PaginationModel<GatherCommitment>>(response => {
        const contactList = new PaginationModel<GatherCommitment>();
        const headers = response.headers;
        contactList.setHeaders(headers);
        contactList.data = response.body.data as GatherCommitment[];
        return contactList;

        }),
        catchError(err => throwError(() => err))
    );
  }

  getCredit(requestParamModel: RequestParamModel): Observable<GatherCreditCompany> {
    return this.baseService.get('gatherco-credit', requestParamModel)
      .pipe(map<any, GatherCreditCompany>(response => {
        const data = response.body.data as GatherCreditCompany;
        return data;
        }),
        catchError(err => throwError(() => err))
    );
  }

  postCredit(data: any): any {
    return this.baseService.post('gatherco-credit/adjust-balance', data).pipe(
      map<any, any>(response => {
        return response;
       }),
      catchError(err => throwError(() => err))
  );
  }



}
