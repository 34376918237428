import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FamilyModel, ProductImportLogModel, ProductImportWarningModel, RequestParamModel } from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FinishModel } from '@coremodels';

@Injectable({
  providedIn: 'root'
})
export class FinishService {

  constructor(private baseService: BaseService) { }
    // get all user list
    getFinish(requestParamModel: RequestParamModel): any {
      return this.baseService.get('finish', requestParamModel)
        .pipe(map<any, any>(response => {
          const accountList = new PaginationModel<FinishModel>();
          const headers = response.headers;
          accountList.setHeaders(headers);
          accountList.data = response.body.data as FinishModel[];
          return accountList;
            }),
        catchError(err => throwError(() => err))
    );
    }
}
