import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FamilyModel, ProductImportLogModel, ProductImportWarningModel, RequestParamModel } from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FamilyService {

  constructor(private baseService: BaseService) { }

  // get all user list
  getFamily(requestParamModel: RequestParamModel): any {
    return this.baseService.get('family', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<FamilyModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as FamilyModel[];
        return accountList;
      }),
      catchError(err => throwError(() => err))
  );
  }
}
