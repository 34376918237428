<header>
  <div class="dashboard-header-main">
    <div class="container-fluid" *ngIf="userLogIn">
      <div class="header-item-container">
        <div class="logo">
          <a [routerLink]="['/dashboard']">
            <img class="desktop-logo" alt="GatherCo" src="{{s3BucketImageURL}}/logo.svg">
            <img class="responsive-logo d-none" alt="GatherCo" src="{{s3BucketImageURL}}/mobile-logo.svg">
          </a>
        </div>
        <div class="header-menu">
          <nav class="navbar navbar-expand-lg">
            <button class="navbar-toggler" type="button" (click)="collapse.toggle()"
                    [attr.aria-expanded]="!isCollapsed"
                    aria-controls="collapseExample">
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div class="collapse navbar-collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed"
                 id="collapsibleNavbar">
              <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/dashboard']">
                    Dashboard
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/accounts']">
                    Accounts
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/contacts']">
                    Contacts
                    <span class="count-label" *ngIf="pendingContactCount">{{pendingContactCount}}</span>
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/sample-requests']">
                    Sample Requests
                    <span class="count-label" *ngIf="sampleCount">{{sampleCount}}</span>
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/quotes']">
                    Quotes
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/orders']">
                    Orders
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/despatch']">
                    Despatch
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/products']">
                    Products
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/users']">
                    Users
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <div ngbDropdown>
                    <a class="nav-link" id="cmsPages" ngbDropdownToggle>CMS</a>
                    <div ngbDropdownMenu aria-labelledby="cmsPages">
                      <a ngbDropdownItem (click)="collapse.toggle()"
                         [attr.aria-expanded]="!isCollapsed" [routerLink]="['/blogs']">
                        Blogs
                      </a>
                      <a ngbDropdownItem (click)="collapse.toggle()"
                         [attr.aria-expanded]="!isCollapsed" [routerLink]="['/faq']">
                        FAQs
                      </a>
                     <a ngbDropdownItem (click)="collapse.toggle()"
                         [attr.aria-expanded]="!isCollapsed" [routerLink]="['/resource']">
                        Resources
                      </a>
                      <a ngbDropdownItem (click)="collapse.toggle()"
                         [attr.aria-expanded]="!isCollapsed" [routerLink]="['/projects']">
                        Projects
                      </a>
                      <a ngbDropdownItem (click)="collapse.toggle()"
                         [attr.aria-expanded]="!isCollapsed" [routerLink]="['/carrier']">
                        Carrier Management
                      </a>
                      <a ngbDropdownItem (click)="collapse.toggle()"
                         [attr.aria-expanded]="!isCollapsed" [routerLink]="['/consumables-calculator']">Consumables
                        Calculator</a>
                      <a ngbDropdownItem (click)="collapse.toggle()"
                         [attr.aria-expanded]="!isCollapsed" [routerLink]="['/email-templates']">Email Templates</a>
                    </div>
                  </div>
                </li>
                <!--                <li class="nav-item" routerLinkActive="active">-->
                <!--                  <a class="nav-link" (click)="collapse.toggle()"-->
                <!--                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/email-templates']">Email Templates</a>-->
                <!--                </li>-->
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/tasks']">
                    Tasks
                    <span class="count-label" *ngIf="pendingTaskCount">{{pendingTaskCount}}</span>
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" (click)="collapse.toggle()"
                     [attr.aria-expanded]="!isCollapsed" [routerLink]="['/settings']">Settings</a>
                </li>

                <!--                <li class="nav-item" routerLinkActive="active">-->
                <!--                  <a class="nav-link" (click)="openSearchDialog()"><i class="fa fa-search"></i></a>-->
                <!--                </li>-->
              </ul>
            </div>
          </nav>
        </div>
        <div class="right-side d-flex align-items-center custom-right-side">
          <div class="icons d-flex align-items-center">
            <a class="nav-link p-0" (click)="openSearchDialog()"><i class="fa fa-search"></i></a>
            <div class="bell-icon" (click)="openNotificationSidebar($event)">

              <span class="notification-count-label" *ngIf="isUnreadNotification">{{unReadNotificationCount}}</span>
              <img src="../../../../assets/admin/images/bell-icon.png" alt="Bell Icon">
            </div>
                    </div>
          <div class="user-dropdown">
            <div ngbDropdown>
              <button ngbDropdownToggle (click)="resetForm();false" type="button" id="user-profile-btn">
                            <span class="user-image">
                                <img class="avatar" src="{{this.identity.profile_picture+constants.IMAGE_SIZE_35_X_35}}"
                                     (error)="helperServices.getErrorImage($event, 'default_profile.png')">
                            </span>
                {{this.identity.first_name | titlecase}} {{this.identity.last_name | titlecase}}
              </button>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" id="user-profile-menu"
                   (click)="$event.stopPropagation();">
                <div class="menu-close-btn">
                  <button #buttonToBeClicked id="close-menu" ngbDropdownToggle>
                    <span></span>
                    <span></span>
                  </button>
                </div>
                <div class="loader-parent profile-change-password-container">
                  <div [class]="submitted?'page-loader':'page-loader d-none'"></div>
                  <form *ngIf="user_detail" role="form" id="user_detail" [formGroup]="userForm"
                        (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
                    <div class="user-details">
                      <div class="user-image">
                        <div class="dropdown_user_image">
                          <button type="button" class="btn form-submit-btn" (click)="file.click()"
                                  id="upload_new_user_profile">
                            <i class="icon-penchil"></i>
                          </button>
                          <input #file type="file" name="profile_picture" id="profile_picture"
                                 class="form-control d-none" (change)="handleFileInput($event)">
                          <img class="avatar" *ngIf="!sanitizedProfilePicture"
                               src="{{ this.profile_picture !== undefined ? this.profile_picture + constants.IMAGE_SIZE_300 : this.identity.profile_picture + constants.IMAGE_SIZE_300}}"
                               (error)="helperServices.getErrorImage($event, 'default_profile.png')">
                          <img class="avatar avatar1" *ngIf="sanitizedProfilePicture"
                               [src]="sanitizedProfilePicture ? sanitizedProfilePicture : constants.NO_IMAGE_PRODUCT">

                        </div>
                      </div>
                      <div class="error invalid-feedback text-center error-user-profile">
                        {{imageError}}
                        Please select valid image type like jpg, jpeg, png & gif.
                      </div>
                      <div class="user-detail-fields">
                        <div class="row menu_dropdown_name_fields">
                          <div class="col">
                            <div class="dropdown-form-field">
                              <label>
                                First Name
                              </label>
                              <input class="form-control"
                                     [ngClass]="{ 'is-invalid': userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched ||isLoading), 'is-valid': !(userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched ||isLoading))}"
                                     formControlName="first_name" type="text" required
                                     placeholder="First Name">
                              <div class="invalid-feedback"
                                   *ngIf="userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched ||isLoading)">
                                                            <span
                                                              *ngIf="userForm.controls['first_name'].errors !== null && userForm.controls['first_name'].errors">
                                                                Please enter first name
                                                            </span>
                                <span
                                  *ngIf="uf.first_name.errors?.apiError">{{uf.first_name.errors?.apiError}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="dropdown-form-field">
                              <label>
                                Last Name
                              </label>
                              <input class="form-control"
                                     [ngClass]="{ 'is-invalid': userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched ||isLoading), 'is-valid': !(userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched ||isLoading))}"
                                     formControlName="last_name" type="text" required
                                     placeholder="Last Name">
                              <div class="invalid-feedback"
                                   *ngIf="userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched ||isLoading)">
                                                            <span
                                                              *ngIf="userForm.controls['last_name'].errors !== null && userForm.controls['last_name'].errors">Please
                                                                enter last name
                                                            </span>
                                <span
                                  *ngIf="uf.last_name.errors?.apiError">{{uf.last_name.errors?.apiError}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="dropdown-form-field">
                              <label>
                                Email
                              </label>
                              <input class="form-control"
                                     [ngClass]="{ 'is-invalid': userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched ||isLoading), 'is-valid': !(userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched ||isLoading))}"
                                     formControlName="email" type="text" required
                                     placeholder="Email">
                              <div class="invalid-feedback"
                                   *ngIf="userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched ||isLoading)">
                                                            <span
                                                              *ngIf="userForm.controls['email'].errors !== null && userForm.controls['email'].errors.required">
                                                                Please enter email address
                                                            </span>
                                <div
                                  *ngIf="uf.email.errors !== undefined && uf.email.errors.email">
                                  Please enter valid email address
                                </div>
                                <span
                                  *ngIf="uf.email.errors?.apiError">{{uf.email.errors?.apiError}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col text-right">
                            <button type="submit" class="btn form-submit-btn"
                                    id="user_profile_save">Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="profile-dropdown-footer">
                      <button (click)="resetPasswordShow()" type="button" class="btn form-submit-btn"
                              id="change_password">Change
                        Password
                      </button>
                      <button (click)="logout()" type="button" class="btn form-submit-btn">Sign Out
                      </button>
                    </div>
                  </form>
                  <form *ngIf="reset_password" [formGroup]="changePasswordForm"
                        (ngSubmit)="changePassword()" id="reset_password"
                        (keydown.enter)="$event.preventDefault()">
                    <div class="user-details">
                      <div class="user-image">
                        <img class="avatar"
                             src="{{profile_picture !== undefined ? profile_picture : this.identity.profile_picture}}"
                             (error)="helperServices.getErrorImage($event, 'default_profile.png')">
                      </div>
                      <div class="row user-detail-fields">
                        <div class="col">
                          <div class="dropdown-form-field">
                            <label>
                              OLD PASSWORD
                            </label>
                            <input class="form-control"
                                   [ngClass]="{ 'is-invalid': cp.old_password.invalid && (cp.old_password.dirty || cp.old_password.touched || isLoading), 'is-valid': !cp.old_password.invalid && (cp.old_password.dirty || cp.old_password.touched || isLoading)}"
                                   formControlName="old_password" type="password" required
                                   placeholder="Old Password">
                            <div
                              *ngIf="cp.old_password.errors !== null &&cp.old_password.invalid && (cp.old_password.dirty || cp.old_password.touched || isLoading)"
                              class="invalid-feedback">
                              <div *ngIf="cp.old_password.errors.required">Old password is
                                required
                              </div>
                              <div *ngIf="cp.old_password.errors.minlength">Old password must
                                be at least 6 characters
                              </div>
                              <div *ngIf="cp.old_password.errors.maxlength">Old password must
                                be less then 25 characters
                              </div>
                              <span
                                *ngIf="cp.old_password.errors?.apiError">{{cp.old_password.errors?.apiError}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="dropdown-form-field">
                            <label>
                              NEW PASSWORD
                            </label>
                            <input class="form-control"
                                   [ngClass]="{ 'is-invalid': cp.new_password.invalid && (cp.new_password.dirty || cp.new_password.touched || isLoading), 'is-valid': !cp.new_password.invalid && (cp.new_password.dirty || cp.new_password.touched || isLoading)}"
                                   formControlName="new_password" type="password"
                                   placeholder="New Password" required>
                            <div
                              *ngIf="cp.new_password.errors !== null && cp.new_password.invalid && (cp.new_password.dirty || cp.new_password.touched || isLoading)"
                              class="invalid-feedback">
                              <div *ngIf="cp.new_password.errors.required">New password is
                                required
                              </div>
                              <div *ngIf="cp.new_password.errors.minlength">New password must
                                be
                                at
                                least 6
                                characters
                              </div>
                              <div *ngIf="cp.new_password.errors.maxlength">New password must
                                be
                                less
                                then 25
                                characters
                              </div>
                              <span
                                *ngIf="cp.new_password.errors?.apiError">{{cp.new_password.errors?.apiError}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="dropdown-form-field">
                            <label>
                              CONFIRM PASSWORD
                            </label>
                            <input class="form-control"
                                   [ngClass]="{ 'is-invalid': cp.confirm_password.invalid && (cp.confirm_password.dirty || cp.confirm_password.touched || isLoading), 'is-valid': !cp.confirm_password.invalid && (cp.confirm_password.dirty || cp.confirm_password.touched || isLoading)}"
                                   formControlName="confirm_password" type="password" required
                                   placeholder="Confirm Password">
                            <div
                              *ngIf="cp.confirm_password.errors !== null &&cp.confirm_password.invalid && (cp.confirm_password.dirty || cp.confirm_password.touched || isLoading)"
                              class="invalid-feedback">
                              <div *ngIf="cp.confirm_password.errors.required">Confirm
                                password is
                                required
                              </div>
                              <div *ngIf="cp.confirm_password.errors.minlength">Confirm
                                password
                                must
                                be
                                at
                                least 6
                                characters
                              </div>
                              <div *ngIf="cp.confirm_password.errors.maxlength">Confirm
                                password
                                must
                                be
                                less
                                then 25
                                characters
                              </div>
                              <div *ngIf="cp.confirm_password.errors.passwordNotMatch">
                                Password
                                and Confirm Password does not
                                match
                              </div>
                              <span
                                *ngIf="cp.confirm_password.errors?.apiError">{{cp.confirm_password.errors?.apiError}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">

                        <div class="col text-right">
                          <button type="submit" class="btn form-submit-btn"
                                  id="reset_password_save">Save
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="profile-dropdown-footer">
                      <button (click)="back()" type="button" class="btn form-submit-btn"
                              id="back_to_profile">Back
                      </button>
                      <button (click)="logout()" type="button" class="btn form-submit-btn">Sign Out
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-notification-sidebar *ngIf="isOpenNotificationSidebar"></app-notification-sidebar>
