import {Injectable} from '@angular/core';
import {BaseService} from '../utils/base.service';
import {catchError, map} from 'rxjs/operators';
import {RequestParamModel} from '../models/request-params.model';
import {PaginationModel} from '../models';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private baseService: BaseService) {
  }

  markNotificationAsRead(requestModel: any, notificationId: any) {
    if (notificationId) {
      return this.baseService.patch('notification/' + notificationId, requestModel)
        .pipe(map<any, number>(response => {
            requestModel = response as any;
            return requestModel;
          }),
          catchError(err => throwError(() => err))
      );
    }
  }

  getUserNotifications(requestParamModel: RequestParamModel): any {
    return this.baseService.get('notification', requestParamModel)
      .pipe(map<any, any>(response => {
        const responseList = new PaginationModel<any>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        responseList.data = response.body.data as any[];
        return responseList;
        }),
      catchError(err => throwError(() => err))
  );
  }

  markAllNotificationsAsRead(requestData: any) {
    return this.baseService.post('notification/mark-all-as-read', requestData)
      .pipe(map<any, any>(response => {
        return response as any;
      }),
      catchError(err => throwError(() => err))
  );
  }

  sendNotification(requestModel: any) {
    return this.baseService.uploadPost(`notification`, requestModel).pipe(
      map<any, any>(response => {
        return response.data;
      }),
      catchError(err => throwError(() => err))
  );
  }


  deleteNotification(_id: any) {
    return this.baseService.delete('notification/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }),
        catchError(err => throwError(() => err))
    );
  }

  updateNoteReactions(requestData: any) {
    return this.baseService.post('note-reaction', requestData)
      .pipe(map<any, any>(response => {
        return response as any;
     }),
      catchError(err => throwError(() => err))
  );
  }

  deleteNoteReactions(_id: any) {
    return this.baseService.delete('note-reaction/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }),
        catchError(err => throwError(() => err))
    );
  }

}
