import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Base64Service} from './base64.service';
import {RequestParamModel} from '../models/request-params.model';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  /** Region: Variable Declaration */
  private readonly API_URL: string = environment.apiUrl;
  private readonly CLIENT_ID: string = environment.clientId;
  private readonly CLIENT_SECRET: string = environment.clientSecret;
  private readonly GRANT_TYPE: string = environment.grantType;

  /** End Region */

  /** Region: Constructor */
  constructor(private http: HttpClient) {
  }

  /** EndRegion */

  /** Region: Public Functions */
  login(url: string, bodyModel: any) {
    // add default authentication params
    bodyModel.scope = '';
    bodyModel.client_id = bodyModel.client_id || this.CLIENT_ID;
    bodyModel.client_secret = bodyModel.client_secret || this.CLIENT_SECRET;
    bodyModel.grant_type = this.GRANT_TYPE;
    const apiURL = this.getAuthURL(url);
    const headers = this.getHeaders(false);
    return this.http.post(apiURL, bodyModel, {
      headers: headers
    });
  }

  get(url: string, requestParamModel?: any) {
    const fullURL = this.getURL(url, requestParamModel);
    const headers = this.getHeaders();
    return this.http.get(fullURL, {
      headers: headers,
      observe: 'response'
    });
  }

  post(url: string, bodyModel: any) {
    const fullURL = this.getURL(url);
    const headers = this.getHeaders();
    return this.http.post(fullURL, bodyModel, {
      headers: headers
    });
  }

  uploadPut(url: string, bodyModel: FormData) {
    const fullURL = this.getURL(url);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', this.getAccessToken());
    return this.http.put(fullURL, bodyModel, {
      headers: headers
    });
  }

  uploadPost(url: string, bodyModel: FormData) {
    const fullURL = this.getURL(url);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', this.getAccessToken());
    return this.http.post(fullURL, bodyModel, {
      headers: headers
    });
  }

  postWithoutToken(url: string, bodyModel: any) {
    const fullURL = this.getURL(url);
    const headers = this.getHeaders(false);
    return this.http.post(fullURL, bodyModel, {
      headers: headers
    });
  }

  getWithoutToken(url: string, requestParamModel?: RequestParamModel) {
    const fullURL = this.getURL(url, requestParamModel);
    const headers = this.getHeaders(false);
    return this.http.get(fullURL, {
      headers: headers,
      observe: 'response'
    });
  }

  patchWithoutToken(url: string, bodyModel: any) {
    const fullURL = this.getURL(url);
    const headers = this.getHeaders(false);
    return this.http.patch(fullURL, bodyModel, {
      headers: headers
    });
  }

  put(url: string, bodyModel: any) {
    const fullURL = this.getURL(url);
    const headers = this.getHeaders();
    return this.http.put(fullURL, bodyModel, {
      headers: headers
    });
  }

  patch(url: string, bodyModel: any) {
    const fullURL = this.getURL(url);
    const headers = this.getHeaders();
    return this.http.patch(fullURL, bodyModel, {
      headers: headers
    });
  }

  delete(url: string) {
    const fullURL = this.getURL(url);
    const headers = this.getHeaders();
    return this.http.delete(fullURL, {
      headers: headers
    });
  }

  /** Region: Public Functions */

  /** Region: Private Functions */
  private getAuthURL(url: string) {
    const authURL: string = this.API_URL + url;
    return authURL;
  }

  getDownloadFileContent(url: string,) {
    const fullURL = this.getURL(url);

    return fullURL;
  }

  private getURL(url: string, requestParamModel?: RequestParamModel): string {
    let newURL: string = this.API_URL + url;
    if (requestParamModel) {
      newURL += requestParamModel.getParams();
    }
    return newURL;
  }

  private getAccessToken(): string {
    const token = localStorage.getItem('token');
    return 'Bearer ' + token;
  }

  private getHeaders(authorizationHeader: boolean = true): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    if (authorizationHeader) {
      headers = headers.append('Authorization', this.getAccessToken());
    }
    return headers;
  }

  /** EndRegion */
}
