import { Injectable } from '@angular/core';
import {
    AccountModel,
    ProductModel,
    QuoteOrderSampleModel,
    RequestParamModel,
    TradeTypeModel,
} from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AccountsService {

    constructor(private baseService: BaseService) {
    }

    // get all user list
    getAccount(requestParamModel: RequestParamModel): any {
        return this.baseService.get('account', requestParamModel)
        .pipe(
          map<any, any>(response => {
              const accountList = new PaginationModel<AccountModel>();
              const headers = response.headers;
              accountList.setHeaders(headers);
              accountList.data = response.body.data as AccountModel[];
              return accountList;
          }),
          catchError((err: any) => {
              return throwError(() => err);
          })
      );

    }

    // get single user details.
    getAccountAllData(accountId: number, requestParamModel: RequestParamModel): Observable<AccountModel> {
        return this.baseService.get('account/' + accountId, requestParamModel)
        .pipe(
          map<any, AccountModel>(response => response.body.data as AccountModel),
          catchError((err: any) => throwError(() => err))
      );

    }

    // save user if new then add if old then update
    saveAccount(accountModel: any): Observable<number> {
      if (accountModel.id !== undefined) {
          return this.baseService.patch(`account/${accountModel.id}`, accountModel)
              .pipe(
                  map<any, number>(response => {
                      const updatedAccount = response.data as AccountModel;
                      return updatedAccount.id;
                  }),
                  catchError((err: any) => throwError(() => err))
              );
      } else {
          return this.baseService.post('account', accountModel)
              .pipe(
                  map<any, number>(response => {
                      const newAccount = response.data as AccountModel;
                      return newAccount.id;
                  }),
                  catchError((err: any) => throwError(() => err))
              );
      }
  }
    /**
     * Save account with logo
     * @param body
     */

    saveAccountWithLogo(body: any) {
        return this.baseService.uploadPost(`account`, body).pipe(
            map((response:any) => response.data),
            catchError(err => throwError(() => err))
        );
    }

    getAccountAddress(requestParamModel: RequestParamModel): any {
        return this.baseService.get('account-address', requestParamModel).pipe(
            map((response:any) => {
                const accountList = new PaginationModel<AccountModel>();
                accountList.setHeaders(response.headers);
                accountList.data = response.body.data as AccountModel[];
                return accountList;
            }),
            catchError(err => throwError(() => err))
        );
    }

    saveAccountAddress(accountModel: any): Observable<number> {
        return this.baseService.post('account-address', accountModel).pipe(
            map((response:any) => response.data.id),
            catchError(err => throwError(() => err))
        );
    }

    updateAccountAddress(model: any): Observable<number> {
        return this.baseService.patch(`account-address/${model.id}`, model).pipe(
            map((response:any) => response.data.id),
            catchError(err => throwError(() => err))
        );
    }

    saveContactAddress(accountModel: any): Observable<any> {
        return this.baseService.post('frontend-user-address', accountModel).pipe(
            map((response:any) => response.data),
            catchError(err => throwError(() => err))
        );
    }

    viewAddress(id: any, accountModel: any): Observable<any> {
        return this.baseService.get(`frontend-user-address/${id}`, accountModel).pipe(
            map((response:any) => response?.body?.data),
            catchError(err => throwError(() => err))
        );
    }

    deleteAccount(_id: any) {
        return this.baseService.delete(`account/${_id}`).pipe(
            map(response => response),
            catchError(err => throwError(() => err))
        );
    }

    deleteAccountAddress(_id: any) {
        return this.baseService.delete(`account-address/${_id}`).pipe(
            map(response => response),
            catchError(err => throwError(() => err))
        );
    }

    uploadLogo(_id: any, body: any) {
        return this.baseService.uploadPost(`account/update-logo/${_id}`, body).pipe(
            map((response:any) => response.data),
            catchError(err => throwError(() => err))
        );
    }

    getAccountByABN(requestModel: AccountModel): any {
        return this.baseService.postWithoutToken(
            'account/get-by-abn?expand=companyAddresses,companyAddresses.fullAddress,defaultAddress',
            requestModel
        ).pipe(
            map((response:any) => response.data),
            catchError(err => throwError(() => err))
        );
    }

    getTradeTypeLists(requestParamModel: RequestParamModel): any {
        return this.baseService.getWithoutToken('trade-type', requestParamModel).pipe(
            map((response:any) => {
                const responseList = new PaginationModel<TradeTypeModel>();
                responseList.setHeaders(response.headers);
                responseList.data = response.body.data as TradeTypeModel[];
                return responseList;
            }),
            catchError(err => throwError(() => err))
        );
    }

    getAccountByCompanyName(requestModel: any) {
        return this.baseService.postWithoutToken('account/get-by-company-name?expand=companyAddresses', requestModel).pipe(
            map((response:any) => response.data),
            catchError(err => throwError(() => err))
        );
    }

    getNote(requestModel: any) {
        return this.baseService.get('note', requestModel).pipe(
            map((response:any) => response.body.data),
            catchError(err => throwError(() => err))
        );
    }


    addUpdateNote(requestModel: any) {
      if (requestModel.noteId) {
          return this.baseService.patch(`note/${requestModel.noteId}`, requestModel).pipe(
              map((response:any) => response.data as QuoteOrderSampleModel),
              catchError(err => throwError(() => err))
          );
      } else {
          return this.baseService.post('note', requestModel).pipe(
              map((response:any) => response.data as QuoteOrderSampleModel),
              catchError(err => throwError(() => err))
          );
      }
  }

  addUpdateNoteWithAttachment(requestModel: any, noteId?: any) {
      const endpoint = noteId ? `note/upload/${noteId}` : 'note';
      return this.baseService.uploadPost(endpoint, requestModel).pipe(
          map((response:any) => response.data),
          catchError(err => throwError(() => err))
      );
  }

  deleteNote(_id: any) {
      return this.baseService.delete(`note/${_id}`).pipe(
          map(response => response),
          catchError(err => throwError(() => err))
      );
  }

  downloadFileContent(bodyData: any) {
      return this.baseService.getDownloadFileContent(`note/download?id=${bodyData.id}`);
  }

  previewUploadFile(id: any) {
      return this.baseService.get(`note/preview/${id}`).pipe(
          map((response:any) => response.body.data),
          catchError(err => throwError(() => err))
      );
  }

  deleteSelectedPdfFile(_id: any) {
      return this.baseService.delete(`note/delete-attachment/${_id}`).pipe(
          map(response => response),
          catchError(err => throwError(() => err))
      );
  }

  getComapnyName(requestParamModel: RequestParamModel): any {
      return this.baseService.get('product', requestParamModel).pipe(
          map((response:any) => {
              const accountList = new PaginationModel<ProductModel>();
              accountList.setHeaders(response.headers);
              accountList.data = response.body.data as ProductModel[];
              return accountList;
          }),
          catchError(err => throwError(() => err))
      );
  }


}
