
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VersionComponent} from './components/version/version.component';
import {TitleService} from './services/title/title.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {
  AlertService,
  AuthGuardService,
  AuthService,
  Base64Service,
  BaseService,
  ModalDialogService,
  NavbarService,
  SpinnerService,
  SubscribersService,

} from './services';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ErrorInterceptor, SpinnerInterceptorService} from './interceptor';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {HeaderComponent} from './components/header/header.component';
import {RouterModule} from '@angular/router';
import {TableSortColumnComponent} from './components/table-sort-column/table-sort-column.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {ConsoleSearchDialogComponent} from './components/header/console-search-dialog/console-search-dialog.component';

import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {BsModalRef, BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {NgSelectModule} from '@ng-select/ng-select';
// NGX Bootstrap Datepicker

import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {DateAgoPipe} from './services/pipes/date-ago.pipe';
import {NumberDirective} from './services/directive/number.directive';
import {CurrencyInputDirective} from './services/directive/currency-input.directive';
import {AbnNumberPipe} from './services/pipes/abn-number.pipe';
import {PhonePipe} from './services/pipes/phone.pipe';
import {IMaskModule} from 'angular-imask';
import {BackendThemeOptions} from './backend-theme-options';
import {InformationDialogComponent} from './dialog/information-dialog/information-dialog.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {AutofocusDirective} from './services/directive/autofocus.directive';
import {ChangeQuantityComponent} from './components/change-quantity/change-quantity.component';
import {CommonSingleInputFormComponent} from './components/common-single-input-form/common-single-input-form.component';
import {ProductSelectPopupComponent} from './components/product-select-popup/product-select-popup.component';
import {AppDragDirective} from './directives/app-drag.directive';
import {DecimalInputDirective} from './directives/decimal-input.directive';
import {RatingGuideComponent} from './components/rating-guide/rating-guide.component'
import {NotFoundComponent} from './components/not-found/not-found.component';
import {NgxSummernoteModule} from 'ngx-summernote';
import {MobileNumberPipe} from './services/pipes/mobile-number.pipe';
import {ActivityListComponent} from './components/activity-list/activity-list.component';
import {UploadFileDirective} from './directives/upload-file.directive';
import {UploadMultipleFileDirective} from './directives/upload-multiple-file.directive';
import {CachedSrcDirective} from './directives/cached-src.directive';
import {TaskDescriptionPipe} from "./services/pipes/task-description.pipe";
import {NotificationSidebarComponent} from './components/header/sidebar/notification-sidebar/notification-sidebar.component';
import {HighchartService} from './services/highchart.service';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ActivityDescriptionPipe } from './services/pipes/activity-description.pipe';
import { ProductLeadTimePipe } from './services/pipes/product-lead-time-pipe';
import { AccessoryNamePipe } from './services/pipes/accessory-name.pipe';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

export const interceptorProviders =
  [
    {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ];
//#region Directives
var directives = []

//#endregion
@NgModule({ declarations: [
        VersionComponent,
        HeaderComponent,
        TableSortColumnComponent,
        PaginationComponent,
        ConfirmModalComponent,
        DateAgoPipe,
        NumberDirective,
        CurrencyInputDirective,
        AppDragDirective,
        AbnNumberPipe,
        PhonePipe,
        InformationDialogComponent,
        AutofocusDirective,
        ChangeQuantityComponent,
        CommonSingleInputFormComponent,
        ProductSelectPopupComponent,
        DecimalInputDirective,
        RatingGuideComponent,
        NotFoundComponent,
        MobileNumberPipe,
        TaskDescriptionPipe,
        ActivityListComponent,
        UploadFileDirective,
        UploadMultipleFileDirective,
        CachedSrcDirective,
        ConsoleSearchDialogComponent,
        NotificationSidebarComponent,
        NotificationsComponent,
        ActivityDescriptionPipe,
        ProductLeadTimePipe,
        AccessoryNamePipe
    ],
    exports: [
        PhonePipe,
        AbnNumberPipe,
        DateAgoPipe,
        NumberDirective,
        CurrencyInputDirective,
        AppDragDirective,
        AutofocusDirective,
        VersionComponent,
        HeaderComponent,
        TableSortColumnComponent,
        FormsModule,
        ReactiveFormsModule,
        ReactiveFormsModule,
         BsDropdownModule,
        RouterModule,
        PaginationComponent,
        PaginationModule,
        ConfirmModalComponent,
        ModalModule,
        NgSelectModule,
        BsDatepickerModule,
        IMaskModule,
        // GooglePlaceModule,
        InformationDialogComponent,
        NgbModule,
        AutocompleteLibModule,
        TooltipModule,
        LazyLoadImageModule,
        DecimalInputDirective,
        RatingGuideComponent,
        NgxSummernoteModule,
        NotFoundComponent,
        MobileNumberPipe,
        ActivityListComponent,
        UploadFileDirective,
        UploadMultipleFileDirective,
        CachedSrcDirective,
        TaskDescriptionPipe,
        NotificationsComponent,
        ActivityDescriptionPipe,
        ProductLeadTimePipe,
        AccessoryNamePipe,
        NgxGpAutocompleteModule
    ], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        RouterModule,
        PaginationModule,
        NgSelectModule,
        BsDatepickerModule.forRoot(),
        IMaskModule,
        // GooglePlaceModule,
        NgxGpAutocompleteModule,
        NgbModule,
        AutocompleteLibModule,
        TooltipModule,
        LazyLoadImageModule], providers: [
        AlertService,
        AuthService,
        AuthGuardService,
        BaseService,
        Base64Service,
        AlertService,
        NavbarService,
        ModalDialogService,
        SpinnerService,
        TitleService,
        BsModalService,
        BackendThemeOptions,
        HighchartService,
        interceptorProviders,
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: environment.GOOGLE_MAP_KEY,
                libraries: ['places']
            })
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })


export class AdminCoreModule {
}
