import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserModel, LoginModel, SessionModel, ResponseModel, ResetPasswordModel, ChangePasswordModel, VerificationTokenModel } from '../../models';
import { BaseService } from '../../utils/base.service';
import { AdminUserModel } from '../../models/admin_user.model';


//#region Injector
@Injectable({
  providedIn: 'root'
})
//#endregion

//#region Auth Service
export class AuthService {

  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;

  //#region  Constructor
  constructor(private baseService: BaseService) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('admin_user')!));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  //#endregion

  //#region public methods
  public isUserAuthenticated(): boolean {
    const adminDetail = localStorage.getItem('admin_user');
    // Check whether the token is expired and return
    // true or false
    return adminDetail != null && adminDetail != undefined;
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  login(requestModel: LoginModel): Observable<SessionModel> {
    return this.baseService.login('oauth/token', requestModel)
      .pipe(map<SessionModel, SessionModel>(response => {
        return response;
      }),
      catchError(err => throwError(() => err))
  );
  }
  verifyAuthToken(verificationTokenModel: any): Observable<ResponseModel> {
    return this.baseService.post('oauth/verify-code', verificationTokenModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }),
      catchError(err => throwError(() => err))
  );
  }
  getLoginUserDetail(): Observable<any> {
    return this.baseService.get('oauth/login-user')
      .pipe(map<HttpResponse<any>, any>(response => {
        return response.body.data as any;
      }),
      catchError(err => throwError(() => err))
  );
  }
  resendVerificationCode(verificationTokenModel: any): Observable<ResponseModel> {
    return this.baseService.postWithoutToken('oauth/resend-verification-code', verificationTokenModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }),
      catchError(err => throwError(() => err))
  );
  }
  getSetting(): Observable<any> {
    return this.baseService.get('setting')
      .pipe(map<HttpResponse<any>, any>(response => {
        return response.body.data as any;
      }),
      catchError(err => throwError(() => err))
  );
  }
  getSettingViewDetail(): Observable<any> {
    return this.baseService.get('setting/view')
      .pipe(map<HttpResponse<any>, any>(response => {
        return response.body.data as any;
      }),
      catchError(err => throwError(() => err))
  );
  }

  forgotPassword(forgotPasswordModel: any): Observable<ResponseModel> {
    return this.baseService.postWithoutToken('user/forgot-password', forgotPasswordModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
      }),
      catchError(err => throwError(() => err))
  );
  }

  changePassword(changePasswordModel: ChangePasswordModel): Observable<ResponseModel> {
    return this.baseService.post('user/change-password', changePasswordModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
    }),
        catchError(err => throwError(() => err))
    );
  }
  verifyToken(resetPasswordModel: ResetPasswordModel): Observable<ResponseModel> {
    return this.baseService.getWithoutToken('adminauth/reset/' + resetPasswordModel.password_reset_token)
      .pipe(map<HttpResponse<any>, any>(response => {
        return response;
    }),
        catchError(err => throwError(() => err))
    );
  }
  resetPassword(resetPasswordModel: ResetPasswordModel): Observable<ResponseModel> {
    return this.baseService.postWithoutToken('user/reset-password', resetPasswordModel)
      .pipe(map<any, ResponseModel>(response => {
        return response.data;
    }),
        catchError(err => throwError(() => err))
    );
  }
  updateProfile(requestModel: UserModel): Observable<UserModel> {
    return this.baseService.patch('oauth/update-user-profile', requestModel)
      .pipe(map<any, UserModel>(response => {
        return response.data;
    }),
        catchError(err => throwError(() => err))
    );
  }
  updateProfilePicture(requestModel: any): Observable<any> {
    return this.baseService.uploadPost('oauth/update-profile-picture', requestModel)
      .pipe(map<any, any>(response => {
        return response.data;
    }),
        catchError(err => throwError(() => err))
    );
  }
  setAdminDetail(detail: any) {
    let model: AdminUserModel = { id: detail.id, first_name: detail.first_name, last_name: detail.last_name, email: detail.email, role: detail.role, profile_picture: detail.profile_picture };
    localStorage.setItem("admin_user", JSON.stringify(model));
  }

  clearToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('loader');
    localStorage.removeItem('admin_user');
  }
  public ngOnDestroy(): void {
    this.currentUserSubject.unsubscribe();
  }
  //#endregion
}
//#endregion
