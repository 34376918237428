import { HttpErrorResponse } from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {SessionModel} from '../../models/session.model';
import {Base64Service} from '../../utils/base64.service';
import * as moment from 'moment';
import {ResizeEvent} from 'angular-resizable-element';
import {exportPDF, Group} from '@progress/kendo-drawing';
import {GoogleAddressModel} from '@coremodels';
import {AlertService} from '..';
import {Constants} from '../../constants';
import {isPlatformBrowser} from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor(
        private notificationService: AlertService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    parseFloat(value: any): any {
        if (!isNaN(value)) {
            parseFloat(value);
        } else {
            return 0.0;
        }
    }

    parseInt(value: any): any {
        if (!isNaN(value)) {
            parseInt(value);
        } else {
            return 0;
        }
    }

    isNumber(value: any) {
        return !isNaN(value);
    }

    setToken(sessionModel: SessionModel): void {
        localStorage.removeItem('token');
        if (sessionModel.access_token !== undefined) {
            const base64Value = Base64Service.encode(sessionModel.access_token);
            localStorage.setItem('token', base64Value);
        }

    }

    encodeData(storeData: string, localStorageText: string): void {
        localStorage.removeItem(localStorageText);
        const base64Value = Base64Service.encode(storeData);
        localStorage.setItem(localStorageText, base64Value);
    }

    decodeData(storedData: string): string {
        const normalText = Base64Service.decode(storedData);
        return normalText;
    }

    //#region Error Handeling
    handleError(error: HttpErrorResponse) {
        if (error.status == 422) {
            if (error.error.data[0].message) {
            }
        } else if (error?.status == 500) {
            this.notificationService.error(error.error.data.message);
        } else {
            if (error?.error?.data?.message) {
            }
        }
    }

    handleMultipleErrors(error: HttpErrorResponse) {
        if (error.status === 422) {
            if (error.error !== undefined && error.error.data !== undefined) {
                const msgArr = error.error.data;
                for (const item of msgArr) {
                }
            }
        } else {
            if (error.message) {
            }
        }
    }

    scrollIntoView(idOfElement: string) {
        if (idOfElement !== null) {

            document.getElementById(idOfElement)!.scrollIntoView({
                behavior: 'smooth'
            });

        }
    }

    //#region general functions
    removeProprtyFromList(list: any) {
        list = list.filter(function (props?: any) {
            if (props.searchValue) {
                delete props.searchValue;
            }

            return true;
        });
    }


    //#endregion

    //#endregion

    /**
     *
     * @param date
     */
    formatDate(date: string) {
        return (date != null || date != '' || date != undefined) ? moment(date).format('DD-MM-YYYY') : '';
    }

    resizeColumn(event: ResizeEvent, columnName: any) {
        if (event.edges.right) {
            const rowWidth = document.getElementsByClassName('header_row');
            const parentWidth = rowWidth[0].clientWidth;
            const childWidth = event.rectangle.width;
            const columnElts = document.getElementsByClassName('header_column_' + columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = childWidth + 'px';
            }
        }
    }

    /**
     * Export PDF
     */
    // public export(pdfComponent: any, fileName: any): void {
    //     pdfComponent.export().then((group: Group) => exportPDF(group)).then((dataUri?: any) => {
    //         const base64 = dataUri.replace('data:application/pdf;base64,', '');
    //         const fileObject = this.dataURLtoFile(dataUri, fileName);
    //         const file = new Blob([fileObject], {type: 'application/pdf'});
    //         if (isPlatformBrowser(this.platformId)) {
    //             const fileURL = window.URL.createObjectURL(fileObject);
    //             if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //                 window.navigator.msSaveOrOpenBlob(file, fileName);
    //             } else {
    //                 const blobURL = URL.createObjectURL(file);
    //                 window.open(blobURL);
    //             }
    //         }
    //     });
    // }
    public export(pdfComponent: any, fileName: any): void {
        pdfComponent.export().then((group: Group) => exportPDF(group)).then((dataUri?: any) => {
            const base64 = dataUri.replace('data:application/pdf;base64,', '');
            const fileObject = this.dataURLtoFile(dataUri, fileName);
            const file = new Blob([fileObject], { type: 'application/pdf' });

            if (isPlatformBrowser(this.platformId)) {
                const blobURL = URL.createObjectURL(file);

                // Use a temporary anchor to trigger the download
                const a = document.createElement('a');
                a.href = blobURL;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                // Cleanup
                URL.revokeObjectURL(blobURL);
                document.body.removeChild(a);
            }
        });
    }


    public dataURLtoFile(dataurl?: any, filename?: any) {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }


    /**
     * Common Function for the Google Address
     * @param address
     * @returns
     */
    public addressHandler(address) {
        let isStreet = false
        let addressModel = new GoogleAddressModel();
        if (address !== undefined && address.formatted_address !== undefined) {
            let streetAddress = '';
            address.address_components.map((component: any) => {
                const componentType = component.types[0];
                switch (componentType) {
                    case "subpremise": {
                        streetAddress =  streetAddress + component.long_name + "/"
                        break;
                    }
                    case "street_number": {
                        isStreet = true;
                        streetAddress = streetAddress + component.long_name;
                        break;
                    }
                    case "premise": {
                        streetAddress = streetAddress + (streetAddress != '' ? "," : "") + " ";
                        break;
                    }
                    case "route": {
                        let addressRoute = component.long_name;
                        if (streetAddress != '') {
                            addressRoute = streetAddress + ", " + component.long_name;
                        }
                        addressModel.address_line_1 = addressRoute;
                        break;
                    }
                    case "sublocality_level_3": {
                        addressModel.address_line_2 = component.long_name;
                        break;
                    }
                    case "postal_code": {
                        addressModel.post_code = component.long_name;
                        break;
                    }
                    case "locality": {
                        addressModel.suburb = component.long_name;
                        break;
                    }
                    case "administrative_area_level_1": {
                        addressModel.state_region = component.short_name;
                        break;
                    }
                    case "administrative_area_level_2": {
                        addressModel.town_city = component.long_name;
                        break;
                    }
                    case "country": {
                        addressModel.country = component.long_name;
                        break;
                    }
                }
                return true;
            });
            addressModel.isStreetAddress = isStreet
            return addressModel;
        }
    }

    public getErrorImage(event: any, image?: any) {
        if (image == "" || image == undefined) {
            event.target.src = Constants.NO_IMAGE_PRODUCT;
            // event.target.src = localStorage.getItem(
            //     'front_image_kit_url'
            // ) + '/' + environment.imageKitAssetFolder+'/no-image-product-small.svg?tr=w-30,h-30'
        } else {
            // event.target.src = Constants.S3_BUCKET_URL + '/' + image;
            event.target.src = Constants.S3_PRODUCT_RESOURCE_IMAGE_KIT_URL + '/WEBSITE_ASSET_STAGING' + '/' + image;
        }
    }
}
