import { Injectable } from '@angular/core';
import { RequestParamModel } from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  constructor(private baseService: BaseService) { }

  // Get product collection Lists
  getCollection(requestParamModel: RequestParamModel): any {
    return this.baseService.get('product-collection', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<any>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as any[];
        return accountList;
      }),
      catchError(err => throwError(() => err))
  );
  }
}
