import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { PaginationModel, ProjectCategoryModel, RequestParamModel } from '../models';
import { BaseService } from '../services';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectCategoryService {

  constructor(
    public baseService: BaseService
  ) { }

  public API_CONSTANT = 'project-category'

  getProjectsCategory(requestParamsModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT, requestParamsModel ? requestParamsModel : new RequestParamModel()).pipe(
      map<any, any>(response => {
        const accountList = new PaginationModel<ProjectCategoryModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ProjectCategoryModel[];
        return accountList;
        }),
        catchError(err => throwError(() => err))
    );
  }

  saveProjectsCategory(body: any) {
    if (body.id) {
      return this.baseService.patch(`${this.API_CONSTANT}/${body.id}`, body).pipe(
        map<any, any>(response => {
          return response;
        }),
        catchError(err => throwError(() => err))
    );
    } else {
      return this.baseService.post(this.API_CONSTANT, body).pipe(
        map<any, any>(response => {
          return response;
        }),
        catchError(err => throwError(() => err))
    );
    }
  }

  getProjectCategoryById(id: number) {
    return this.baseService.get(`${this.API_CONSTANT}/${id}`).pipe(
      map<any, any>(response => {
        return response.body.data as ProjectCategoryModel
        }),
        catchError(err => throwError(() => err))
    );
  }

  deleteProjectCategory(id: number) {
    return this.baseService.delete(`${this.API_CONSTANT}/${id}`).pipe(
      map<any, any>(response => {
        return response;
        }),
        catchError(err => throwError(() => err))
    );
  }
}
