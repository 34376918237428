import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendUserModel, RequestParamModel, ResponseModel, UserModel } from '../models';
import { PaginationModel } from '../models';
import { BaseService } from '../services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BackendUserService {

  constructor(private baseService: BaseService) { }
  // get all user list
  getBackEndUser(requestParamModel: RequestParamModel): any {
    return this.baseService.get('backend-user', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<BackendUserModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as BackendUserModel[];
        return accountList;
        }),
        catchError(err => throwError(() => err))
    );
  }

  delete(_id: any) {
    return this.baseService.delete('backend-user/' + _id)
      .pipe(map<any, number>(response => {
        return response;
      }),
      catchError(err => throwError(() => err))
  );
  }

  // save user if new then add if old then update
  save(accountModel: any): Observable<number> {
    if (accountModel.id !== undefined) {
      return this.baseService.patch('backend-user/' + accountModel.id, accountModel)
        .pipe(map<any, number>(response => {
          accountModel = response.data as BackendUserModel;
          return accountModel.id;
         }),
        catchError(err => throwError(() => err))
    );
     } else {
      return this.baseService.post('backend-user', accountModel)
        .pipe(map<any, number>(response => {
          accountModel = response.data as BackendUserModel;
          return accountModel.id;
        }),
        catchError(err => throwError(() => err))
    );
   }
  }
  signUp(requestModel: any): Observable<UserModel> {
    return this.baseService.uploadPost('user', requestModel)
      .pipe(map<any, UserModel>(response => {
        return response.data;
        }),
        catchError(err => throwError(() => err))
    );
  }
}
