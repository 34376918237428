import { Injectable } from '@angular/core';
// import { data } from 'jquery';

import { catchError, map } from 'rxjs/operators';
import { PaginationModel, RequestParamModel, ResourceCategoryModel, ResourceModel, ResourceSubCategory } from '../models';
import { BaseService } from '../services';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  public API_CONSTANT = 'product-resource';
  public API_CONSTANT_FOR_CATEGORY = 'product-resource-category';
  public API_CONSTANT_FOR_SUBCATEGORY = 'product-resource-sub-category';
  constructor(private baseService: BaseService) { }

  //#region  RESOURCE ACTIONS
  getResourceList(requestParamModel: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT, requestParamModel).pipe
      (map<any, any>(response => {
        const accountList = new PaginationModel<ResourceModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ResourceModel[];
        return accountList;
      }),
      catchError(err => throwError(() => err))
  );
  }

  postResourceList(body: any) {
    if (body.id) {
      return this.baseService.uploadPut(this.API_CONSTANT + '/' + body.id, body).pipe
        (map<any, any>(response => {
          return response
        }),
        catchError(err => throwError(() => err))
    );
    } else {
      return this.baseService.uploadPost(this.API_CONSTANT, body).pipe
        (map<any, any>(response => {
          return response
        }),
        catchError(err => throwError(() => err))
    );
    }
  }

  delete(id: number) {
    return this.baseService.delete(this.API_CONSTANT + `/${id}`).pipe(
      map<any, any>(response => {
        return response;
      }),
      catchError(err => throwError(() => err))
  );
  }

  getByIdDetail(id: any, requestParamModel: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT + `/${id}`, requestParamModel).pipe(
      map<any, any>(response => {
        return response.body.data as ResourceModel;
      }),
      catchError(err => throwError(() => err))
  );
  }
  //#endregion


  //#region RESOURCE CATEGORY ACTION
  getCategoryList(requestParamModel: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT_FOR_CATEGORY, requestParamModel).pipe
      (map<any, any>(response => {
        const accountList = new PaginationModel<ResourceCategoryModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ResourceCategoryModel[];
        return accountList;
      }),
      catchError(err => throwError(() => err))
  );
  }

  postCategory(data: any) {
    if (data.id) {
      return this.baseService.patch(this.API_CONSTANT_FOR_CATEGORY + '/' + data.id, data).pipe(
        map<any, any>(response => {
          return response;
        }),
        catchError(err => throwError(() => err))
    );
    } else {
      return this.baseService.post(this.API_CONSTANT_FOR_CATEGORY, data).pipe(
        map<any, any>(response => {
          return response;
        }),
        catchError(err => throwError(() => err))
    );
    }

  }

  deleteCategory(id: any) {
    return this.baseService.delete(this.API_CONSTANT_FOR_CATEGORY + '/' + id).pipe
      (map<any, any>(response => {
        return response;
      }),
      catchError(err => throwError(() => err))
  );
  }
  //#endregion


  //#region RESOURCE SUB CATEGORY ACTIONS
  getSubCategoryList(requestParamModel: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT_FOR_SUBCATEGORY, requestParamModel).pipe
      (map<any, any>(response => {
        const accountList = new PaginationModel<ResourceSubCategory>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as ResourceSubCategory[];
        return accountList;
      }),
      catchError(err => throwError(() => err))
  );
  }

  postSubCategory(data: any) {
    if (data.id) {
      return this.baseService.patch(this.API_CONSTANT_FOR_SUBCATEGORY + '/' + data.id, data).pipe(
        map<any, any>(response => {
          return response
        }),
        catchError(err => throwError(() => err))
    );
    } else {
      return this.baseService.post(this.API_CONSTANT_FOR_SUBCATEGORY, data).pipe(
        map<any, any>(response => {
          return response
        }),
        catchError(err => throwError(() => err))
    );
    }
  }

  deleteSubcategory(id: any) {
    return this.baseService.delete(this.API_CONSTANT_FOR_SUBCATEGORY + '/' + id).pipe(
      map<any, any>(response => {
        return response;
      }),
      catchError(err => throwError(() => err))
  );
  }
  //#endregion
}
