import { HttpErrorResponse } from '@angular/common/http';
import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {QuoteOrderSampleService} from '../../apiservices';
import {ContactsService} from '../../apiservices/contacts.service';
import {Constants} from '../../constants';
import {PaginationModel, RequestParamModel, UserModel} from '../../models';
import {AlertService, AuthService, HelperService, SubscribersService} from '../../services';
import {ConsoleSearchDialogComponent} from './console-search-dialog/console-search-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TasksService} from "../../apiservices/tasks.service";
import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { NotificationService } from '../../apiservices/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', '../../../app.component.css']
})
export class HeaderComponent implements OnInit {

  /* ################### Variable Declaration Start ################### */
  public isCollapsed = true;
  @ViewChild('buttonToBeClicked') buttonToBeClicked!: ElementRef;
  username: any;
  user_detail = true;
  reset_password = false;
  isLoading = false;
  subscriptions = new Array<Subscription>();
  subscribeProfile = new Subscription();
  isOpen!: boolean;
  identity!: UserModel;
  userForm: any;
  changePasswordForm: any;
  submitted = false;
  files: any;
  imageError = '';
  profile_picture: any;
  passwordNotSame = false;
  pendingContactCount = 0;
  sampleCount = 0;
  constants = Constants;
  s3BucketImageURL!: any;
  navExpanded: boolean = false;
  userLogIn: boolean = true;
  pendingTaskCount = 0;
  isOpenNotificationSidebar: boolean = false;
  toggleSidebar: boolean = false;
  sanitizedProfilePicture: SafeUrl | undefined;
  userProfileDetail: any
  isUnreadNotification:boolean = false;
  unReadNotificationCount=0
  /* ################### Variable Declaration End ################### */

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private subscriberService: SubscribersService,
    private authenticationService: AuthService,
    public quoteServices: QuoteOrderSampleService,
    private contactService: ContactsService,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private taskService: TasksService,
    private subscribersService: SubscribersService,
    public helperServices: HelperService,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService
  ) {
    subscriberService.changed_username.subscribe((value) => {
      this.username = value;
    });
    this.subscriberService.updatePendingAccountCount.subscribe((value) => {
      this.getPendingContactCount();
    });
    this.subscriberService.updateSampleOrderCount.subscribe((value) => {
      this.getSampleOrderCountOfOrderReceived();
    });
    this.subscriberService.updateTaskCount.subscribe((value: string) => {
      this.pendingTaskCount = +value;
    })
    this.subscriberService.updateUnReadNotificationCount.subscribe((value: string) => {
      this.getNotifications()
    })
    subscriberService.userLogout.subscribe((value: boolean) => {
      if (value) {
        this.userLogIn = false;
        this.buttonToBeClicked?.nativeElement.click();
        localStorage.removeItem('sampleOrder')
        localStorage.removeItem('token');
        localStorage.removeItem('loader');
        localStorage.removeItem('admin_user');
        this.subscriberService.changed_username.next("");
        window.location.href = '/login';
      }
    })

    this.subscribersService.notificationSidebarClick.subscribe((eventData: boolean) => {
      this.toggleNotificationSidebar(eventData);
    });

    this.subscribersService.isUnReadNotification.subscribe((eventData: boolean) => {
      this.isUnreadNotification = eventData;
    });
  }

  /************************************
   * Life cycle hooks start
   ************************************/

  ngOnInit() {
    this.s3BucketImageURL = Constants.S3_PRODUCT_RESOURCE_IMAGE_KIT_URL + '/WEBSITE_ASSET_STAGING';
    this.getSampleOrderCountOfOrderReceived();
    this.getPendingContactCount();
    this.getPendingTaskCount();
    this.getNotifications();
    // UserForm
    this.userForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
    this.changeDetectorRef.markForCheck();
    this.subscribeProfile =
      this.subscriberService.profileModel.subscribe((data) => {
        if (data.first_name == undefined) {
          this.authenticationService.getLoginUserDetail().subscribe(
            (userModel: UserModel) => {
              if (userModel.profile_picture !== null) {
                userModel.profile_picture = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL + '/' + userModel.profile_picture;
              } else {
                delete userModel.profile_picture;
              }
              this.identity = userModel;
              this.userForm.controls['first_name'].setValue(this.identity.first_name);
              this.userForm.controls['last_name'].setValue(this.identity.last_name);
              this.userForm.controls['email'].setValue(this.identity.email);
            }, (error: any) => {
              if (error.status == 422) {
                this.alertService.error(error, 'Error');
              } else if (error.status == 401) {
                this.alertService.error(error.error.data.message, 'Error');
              }
            });
        }
        this.identity = data;
        this.userForm.controls['first_name'].setValue(this.identity.first_name);
        this.userForm.controls['last_name'].setValue(this.identity.last_name);
        this.userForm.controls['email'].setValue(this.identity.email);
      })
    // UserForm

    // ChangePasswordForm
    this.changePasswordForm = this.fb.group({
      old_password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
      new_password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25), this.passwordMatcher.bind(this)]],
    });
    // ChangePasswordForm
  }

  ngOnDestroy(): void {
    this.subscribeProfile.unsubscribe();
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Other functions start
   ************************************/
  toggleNotificationSidebar(eventData: boolean) {
    let isOpenSidebar = eventData;
    this.subscriberService.isNotificationSidebarOpen.subscribe((eventData: boolean) => {
      if(window.location.pathname === '/notifications'){
        isOpenSidebar = eventData;
      }
    })
    this.isOpenNotificationSidebar = isOpenSidebar
  }

  resetForm() {
    this.user_detail = true;
    this.reset_password = false;
    this.userForm.reset();
    this.changePasswordForm.reset();
    this.ngOnInit();
  }

  logout() {
    this.buttonToBeClicked.nativeElement.click();
    window.location.href = '/login';
    // this.route.navigate(['/login']);
    localStorage.removeItem('sampleOrder')
    localStorage.removeItem('token');
    localStorage.removeItem('loader');
    localStorage.removeItem('admin_user');
    this.subscriberService.changed_username.next("");
    // this.subscriberService.userLoggedOut(true);
    // this.route.navigate(['/login']);
  }

  getSampleOrderCountOfOrderReceived() {
    const requestParamModel = new RequestParamModel();
    requestParamModel.addDefaultFilter('type', Constants.ORDER_TYPE_SAMPLE_SHORT, 'number');
    requestParamModel.addDefaultFilter('quote_status', Constants.ORDER_RECEIVED_SHORT, 'number');
    requestParamModel.fields = ['id']
    requestParamModel.pageSize = 1
    requestParamModel.addDefaultFilter('is_archive', 'N', 'number');
    requestParamModel.addDefaultFilter('is_deleted', '0', 'number');
    this.quoteServices.getQuoteAndOrders(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
      this.isLoading = false;
      requestParamModel.update<any>(dataList);
      this.sampleCount = requestParamModel.totalCount
      this.changeDetectorRef.detectChanges();
    }, (error: HttpErrorResponse) => {
      this.isLoading = false;
    });
  }

  getPendingContactCount() {
    const requestParamModel = new RequestParamModel();
    requestParamModel.addDefaultFilter('status', 'N', 'string');
    requestParamModel.fields = ['contact_id'];
    requestParamModel.pageSize = 1
    this.contactService.getContacts(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
      this.isLoading = false;
      requestParamModel.update<any>(dataList);
      this.pendingContactCount = requestParamModel.totalCount
      this.changeDetectorRef.detectChanges()
    }, (error: HttpErrorResponse) => {
      this.isLoading = false;
    });
  }

  getPendingTaskCount() {
    const requestParamModel = new RequestParamModel();
    requestParamModel.addDefaultFilter('status', 'P', 'string');
    requestParamModel.pageSize = 1;
    this.taskService.getTasksList(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
      this.isLoading = false;
      requestParamModel.update<any>(dataList);
      this.pendingTaskCount = requestParamModel.totalCount
    }, (error: HttpErrorResponse) => {
      this.isLoading = false;
    });
  }

  private passwordMatcher(control: UntypedFormControl): { [s: string]: boolean } {
    if (this.changePasswordForm && (control.value !== this.changePasswordForm.controls.new_password.value)) {
      return {passwordNotMatch: true};
    }
    return {};
  }

  handleFileInput(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].type.includes('image')) {
        if (event.target.files[0].size < 25000000) {
          this.files = <File>event.target.files[0];
          reader.readAsDataURL(this.files);
          reader.onload = () => {
            this.profile_picture = reader.result;
            this.sanitizedProfilePicture = this.sanitizer.bypassSecurityTrustUrl(this.profile_picture); // Sanitize the URL
            this.changeDetectorRef.markForCheck();
          };
        } else {
          this.alertService.info(Constants.USER_UPDATE_PROFILE_CONSTANTS.IMAGE_SIZE_VALIDATION)
        }
      } else {
        this.alertService.info(Constants.USER_UPDATE_PROFILE_CONSTANTS.IMAGE_TYPE_VALIDATION_MESSAGE)
      }

    }
  }

  get uf() {
    return this.userForm.controls;
  }

  get cp() {
    return this.changePasswordForm.controls;
  }

  changePassword() {
    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      this.isLoading = true;
      return;
    } else {
      this.isLoading = false;
    }
    this.submitted = true;
    var changePasswordFormData: any = {
      old_password: this.cp.old_password.value,
      password: this.cp.new_password.value,
    };
    this.authenticationService.changePassword(changePasswordFormData).subscribe((data: any) => {
      this.submitted = false;
      this.buttonToBeClicked.nativeElement.click();
      this.user_detail = true;
      this.reset_password = false;
      this.alertService.success(Constants.SUCCESS_RESET_PASSWORD, 'Success');
    }, (error: any) => {
      this.passwordNotSame = true;
      this.submitted = false;
      if (error.status == 422) {
        let self = this;
        if (error.error.data.length > 0) {
          Object.keys(error.error.data).forEach(function (key, data) {
            self.changePasswordForm.controls[error.error.data[key].field].setErrors({'apiError': error.error.data[key].message});
          });
        }
      }
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.userForm.invalid) {
      this.isLoading = true;
      return;
    } else {
      this.isLoading = false;
    }
    this.submitted = true;
    var userDataForm: any = {
      first_name: this.uf.first_name.value,
      last_name: this.uf.last_name.value,
      email: this.uf.email.value,
    }
    this.authenticationService.updateProfile(userDataForm).subscribe((userModel: UserModel) => {
      if (this.files !== undefined) {
        const frmData = new FormData();
        frmData.append('profile_picture', this.files);
        this.authenticationService.updateProfilePicture(frmData).subscribe((data: any) => {
          this.submitted = false;
          if (data.profile_picture !== null) {
            userModel.profile_picture = Constants.S3_USER_LOGO_IMAGE_KIT_URL + '/' + data.profile_picture;
          }
          this.identity = userModel;
          this.authenticationService.setAdminDetail(userModel);
          this.subscriberService._profileModel.next(userModel);
          this.changeDetectorRef.markForCheck();
          this.buttonToBeClicked.nativeElement.click();
          this.alertService.success(Constants.SUCCESS_USER_PROFILE_UPDATE, 'Success');
        }, (error: any) => {
          this.submitted = false;
          this.files = '';
          if (this.identity.profile_picture) {
            this.profile_picture = this.identity.profile_picture;
          } else {
            this.profile_picture = '';
          }
          if (error.status == 422) {
          }
        });
      } else {
        this.submitted = false;
        if (userModel.profile_picture !== null) {
          userModel.profile_picture = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL + '/' + userModel.profile_picture;
        }
        this.identity = userModel;
        this.authenticationService.setAdminDetail(userModel);
        this.subscriberService._profileModel.next(userModel);
        this.buttonToBeClicked.nativeElement.click();
        this.alertService.success(Constants.SUCCESS_USER_PROFILE_UPDATE, 'Success');
      }
    }, (error: any) => {
      this.submitted = false;
      if (error.status == 422) {
        let self = this;
        if (error.error.data.length > 0) {
          Object.keys(error.error.data).forEach(function (key, data) {
            self.userForm.controls[error.error.data[key].field].setErrors({'apiError': error.error.data[key].message});
          });
        }
      }
    });
  }

  resetPasswordShow() {
    this.reset_password = true;
    this.user_detail = false;
    this.changeDetectorRef.markForCheck();
  }

  back() {
    this.changePasswordForm.reset()
    this.isLoading = false
    this.user_detail = true;
    this.reset_password = false;
  }

  // for searching in  header
  openSearchDialog() {
    const instance = this.modalService.open(ConsoleSearchDialogComponent, {
      centered: false,
      windowClass: 'donationModals quotes-model quote-order-export-pdf-model search-modal',
      backdrop: 'static',
    });

    instance.closed.subscribe((result) => {
      if (result) {
      }
    })
  }

  onClick() {
    this.navExpanded = true;
  }

  openNotificationSidebar(event: Event) {
    event.preventDefault();
    this.toggleSidebar = !this.toggleSidebar;
    this.subscribersService.emitNotificationSidebarToggle(true);
    setTimeout(() => {
      this.subscribersService.notificationSidebarToggle(true);
    }, 500);

  }

  getNotifications(){
    const requestParamModel = new RequestParamModel();
    requestParamModel.expands = ['fromUser', 'toUser', 'note'];
    requestParamModel.pageSize = 'all';
    requestParamModel.removeDefaultFilter('to_user_id');

    let userProfile = localStorage.getItem('admin_user');
    this.userProfileDetail = JSON.parse(userProfile);

    requestParamModel.addDefaultFilter('to_user_id', this.userProfileDetail.id, 'number');
    requestParamModel.sortChange('id', 'desc');
    this.notificationService.getUserNotifications(requestParamModel).subscribe((dataList) => {
      this.unReadNotificationCount = dataList.data.filter(item => item.status === Constants.NOTIFICATION_STATUS_INACTIVE).length;
      this.isUnreadNotification = dataList.data.some(item => item.status === Constants.NOTIFICATION_STATUS_INACTIVE);
       }, (error: HttpErrorResponse) => {
    });
  }

  /************************************
   * Other functions end
   ************************************/
}
