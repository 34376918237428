import { Injectable } from '@angular/core';
import { PaginationModel } from '@coremodels';
import { BaseService } from '@coreservices';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StockAllocationService {

  private draggedProduct = new Subject();
  draggedProduct$ = this.draggedProduct.asObservable();

  constructor(private baseService: BaseService) {
  }

  setDraggedProduct(id: any) {
    this.draggedProduct.next(id)
  }

  getSuppliers(requestParamModel) {
    return this.baseService.get('supplier', requestParamModel).pipe(map((response: any) => {
      return response.body.data;
    }),
      catchError(err => throwError(() => err))
    );
  }

  saveContainer(payload: any): Observable<any> {
    if (payload.id) {
      let id = payload.id;
      delete payload.id;
      return this.baseService.patch(`container/${id}`, payload)
        .pipe(map((response: any) => {
          return response.data;
        }),
          catchError(err => throwError(() => err))
        );
    } else {
      delete payload.id;
      return this.baseService.post('container', payload)
        .pipe(map((response: any) => {
          return response.data;
        }),
          catchError(err => throwError(() => err))
        );
    }
  }

  saveContainerProduct(payload: any): Observable<any> {
    if (payload.id) {
      let id = payload.id;
      delete payload.id;
      return this.baseService.patch(`container-product/${id}`, payload)
        .pipe(map((response: any) => {
          return response.data;
        }),
          catchError(err => throwError(() => err))
        );
    } else {
      return this.baseService.post('container-product', payload)
        .pipe(map((response: any) => {
          return response.data;
        }),
          catchError(err => throwError(() => err))
        );
    }
  }

  syncPOWithUnleashed(poNumber: any) {
    return this.baseService.get(`container/sync-po-with-unleashed?po_number=${poNumber}`).pipe(map((response: any) => {
      return response.body.data;
    }),
      catchError(err => throwError(() => err))
    );
  }

  deleteContainerProduct(_id: any) {
    return this.baseService.delete('container-product/' + _id);
  }

  mergeContainer(payload: any): Observable<any> {
    return this.baseService.post('container/merge', payload)
      .pipe(map((response: any) => {
        return response.data;
      }),
        catchError(err => throwError(() => err))
      );
  }

  getContainers(requestParamModel) {
    return this.baseService.get('container', requestParamModel).pipe(map((response: any) => {
      const list = new PaginationModel<any>();
      const headers = response.headers;
      list.setHeaders(headers);
      list.data = response.body.data as any[];
      return list;
    }),
      catchError(err => throwError(() => err))
    );
  }

  getContainer(requestParamModel, id) {
    return this.baseService.get(`container/${id}`, requestParamModel).pipe(map((response: any) => {
      return response.body.data;
    }),
      catchError(err => throwError(() => err))
    );
  }

  deleteContainer(id: any) {
    return this.baseService.delete(`container/${id}`)
      .pipe(map(response => {
        return response;
      }),
        catchError(err => throwError(() => err))
      );
  }

}
