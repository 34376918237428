import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpdateLogisticDetailModalComponent} from '../sample-requests/modal/update-logistic-detail-modal/update-logistic-detail-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UpdateProjectDetailModalComponent} from './modal/update-project-detail-modal/update-project-detail-modal.component';
import {UpdateContactDetailModalComponent} from './modal/update-contact-detail-modal/update-contact-detail-modal.component';
import {AddUpdateNoteModalComponent} from './modal/add-update-note-modal/add-update-note-modal.component';
import {QuoteInvoiceExportModalComponent} from './modal/quote-invoice-export-modal/quote-invoice-export-modal.component';
import {UpdateSiteContactDetailModalComponent} from './modal/update-site-contact-detail-modal/update-site-contact-detail-modal.component';
import {UpdateStockRequiredDateModalComponent} from './modal/update-stock-required-date-modal/update-stock-required-date-modal.component';
import {UpdateCratesModalComponent} from './modal/update-crates-modal/update-crates-modal.component';
import {OfflinePaymentModalComponent} from './modal/offline-payment-modal/offline-payment-modal.component';
import {PaymentReceiptDetailModalComponent} from './modal/payment-receipt-detail-modal/payment-receipt-detail-modal.component';
import {AdminCoreModule} from "../admin-core/admin-core.module";
import {AddUpdateDeliveryDetailModalComponent} from './modal/add-update-delivery-detail-modal/add-update-delivery-detail-modal.component';
import { UpdateDeliveryInstructionModalComponent } from './modal/update-delivery-instruction-modal/update-delivery-instruction-modal.component';
import {PreviewUploadFileModalComponent } from './modal/preview-upload-file-modal/preview-upload-file-modal.component';
import { FollowUpInternalNoteModalComponent } from './modal/follow-up-internal-note-modal/follow-up-internal-note-modal.component';
import { LinkQuoteOrderModalComponent } from './modal/link-quote-order-modal/link-quote-order-modal.component';
import { LinkedQuoteOrderModalComponent } from './modal/linked-quote-order-modal/linked-quote-order-modal.component';
import { TrackOrderExportModalComponent } from '../orders/order-detail/modal/track-order-export-modal/track-order-export-modal.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { QuillModule } from 'ngx-quill';
import { CreditSpecifierDetailModalComponent } from './modal/credit-specifier-detail-modal/credit-specifier-detail-modal.component'
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { QuoteAddItemSidebarComponent } from '../quotes/sidebar/quote-add-item-sidebar/quote-add-item-sidebar.component';
import { StockAllocationContentComponent } from '../dashboard/stock-allocation/stock-allocation-content/stock-allocation-content.component';
import { AddNewContainerSidebarComponent } from '../dashboard/stock-allocation/add-new-container-sidebar/add-new-container-sidebar.component';
import { DndModule } from 'ngx-drag-drop';
import { ConsumableContentComponent } from '../quote-order-sample-core/consumable-content/consumable-content.component';
import { PreviewDetailComponent } from '../quote-order-sample-core/preview-detail/preview-detail.component';
import { OrderStatusChangeSectionComponent } from '../quote-order-sample-core/order-status-change-section/order-status-change-section.component';
import { FinalPaymentPendingUserdetailsModalComponent } from './modal/final-payment-pending-userdetails-modal/final-payment-pending-userdetails-modal.component';
import {SentMailDetailsComponent} from "../quote-order-sample-core/sent-mail-details/sent-mail-details.component";

import { CollectionAndSizeComponent } from '../quote-order-sample-core/collection-and-size/collection-and-size.component';
@NgModule({
  declarations: [ // Other shared components, directives, and pipes...
    UpdateLogisticDetailModalComponent,
    UpdateProjectDetailModalComponent,
    UpdateContactDetailModalComponent,
    AddUpdateNoteModalComponent,
    QuoteInvoiceExportModalComponent,
    UpdateSiteContactDetailModalComponent,
    UpdateStockRequiredDateModalComponent,
    UpdateCratesModalComponent,
    OfflinePaymentModalComponent,
    PaymentReceiptDetailModalComponent,
    AddUpdateDeliveryDetailModalComponent,
    UpdateDeliveryInstructionModalComponent,
    PreviewUploadFileModalComponent,
    FollowUpInternalNoteModalComponent,
    LinkQuoteOrderModalComponent,
    LinkedQuoteOrderModalComponent,
    TrackOrderExportModalComponent,
    CreditSpecifierDetailModalComponent,
    QuoteAddItemSidebarComponent,
    StockAllocationContentComponent,
    AddNewContainerSidebarComponent,
    ConsumableContentComponent,
    PreviewDetailComponent,
    OrderStatusChangeSectionComponent,
    FinalPaymentPendingUserdetailsModalComponent,
    SentMailDetailsComponent,
    CollectionAndSizeComponent
  ],
  imports: [ // Other modules you might need...
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AdminCoreModule,
    BsDatepickerModule.forRoot(),
    QuillModule.forRoot(),
    NgxGpAutocompleteModule,
    DndModule
],
  exports: [ // Other shared components, directives, and pipes...
    ReactiveFormsModule,
    UpdateLogisticDetailModalComponent,
    UpdateProjectDetailModalComponent,
    UpdateContactDetailModalComponent,
    AddUpdateNoteModalComponent,
    QuoteInvoiceExportModalComponent,
    UpdateSiteContactDetailModalComponent,
    UpdateStockRequiredDateModalComponent,
    UpdateCratesModalComponent,
    OfflinePaymentModalComponent,
    PaymentReceiptDetailModalComponent,
    AddUpdateDeliveryDetailModalComponent,
    CreditSpecifierDetailModalComponent,
    QuoteAddItemSidebarComponent,
    StockAllocationContentComponent,
    AddNewContainerSidebarComponent,
    ConsumableContentComponent,
    OrderStatusChangeSectionComponent,
    CollectionAndSizeComponent
  ],
})
export class SharedModule {
}
