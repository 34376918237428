import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { CommonCategoryModel, PaginationModel, RequestParamModel } from '../models';
import { BlogModel } from '../models/blog.model';
import { BaseService } from '../services';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  public API_CONSTANT = "blog";
  public API_CONSTANT_FOR_CATEGORY = 'blog-category'
  constructor(private baseService: BaseService) { }

  getBlogs(requestParaModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT, requestParaModel).pipe(
      map<any, any>(response => {
        const contactList = new PaginationModel<BlogModel>();
        const headers = response.headers;
        contactList.setHeaders(headers);
        contactList.data = response.body.data as BlogModel[];
        return contactList;
        }),
      catchError(err => throwError(() => err))
  );
  }

  getBlogById(id: number, requestParaModel?: RequestParamModel) {
    return this.baseService.get(`${this.API_CONSTANT}/${id}`, requestParaModel).pipe(
      map<any, any>(response => {
        return response.body;
        }),
        catchError(err => throwError(() => err))
    );
  }

  saveBlogs(body: any) {
    if (body?.id) {
      return this.baseService.patch(`${this.API_CONSTANT}/${body.id}`, body).pipe(
        map<any, any>(response => {
          return response;
      }),
        catchError(err => throwError(() => err))
    );
    } else {
      return this.baseService.uploadPost(`${this.API_CONSTANT}`, body).pipe(
        map<any, any>(response => {
          return response.body;
      }),
        catchError(err => throwError(() => err))
    );
    }
  }

  deleteBlog(id: any) {
    return this.baseService.delete(`${this.API_CONSTANT}/${id}`).pipe
      (map<any, any>(response => {
        return response.body
      }),
      catchError(err => throwError(() => err))
  );
  }


  saveBlogImages(body: any) {
    return this.baseService.uploadPost(`${this.API_CONSTANT}/upload`, body).pipe(
      map<any, any>(response => {
        return response
        }),
        catchError(err => throwError(() => err))
    );
  }

  //#region BLOG CATEGORY ACTION
  getCategoryList(requestParamModel: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANT_FOR_CATEGORY, requestParamModel).pipe
      (map<any, any>(response => {
        const accountList = new PaginationModel<CommonCategoryModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as CommonCategoryModel[];
        return accountList;
      }),
      catchError(err => throwError(() => err))
  );
  }

  postCategory(data: any) {
    if (data.id) {
      return this.baseService.patch(this.API_CONSTANT_FOR_CATEGORY + '/' + data.id, data).pipe(
        map<any, any>(response => {
          return response;
        }),
        catchError(err => throwError(() => err))
    );
    } else {
      return this.baseService.post(this.API_CONSTANT_FOR_CATEGORY, data).pipe(
        map<any, any>(response => {
          return response;
           }),
        catchError(err => throwError(() => err))
    );
    }
  }

  deleteCategory(id: any) {
    return this.baseService.delete(this.API_CONSTANT_FOR_CATEGORY + '/' + id).pipe
      (map<any, any>(response => {
        return response;
        }),
      catchError(err => throwError(() => err))
     );
  }

}
