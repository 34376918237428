import {Injectable} from '@angular/core';
import {BaseService} from '../utils/base.service';
import {RequestParamModel} from '../models/request-params.model';
import {catchError, map} from 'rxjs/operators';
import {PaginationModel} from '../models/pagination.model';
import {TaskModel} from '../models'
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private baseService: BaseService) {
  }

  getTasksList(requestParamModel: RequestParamModel): any {
    return this.baseService.get('task', requestParamModel)
      .pipe(map<any, any>(response => {
        const taskList = new PaginationModel<TaskModel>();
        const headers = response.headers;
        taskList.setHeaders(headers);
        taskList.data = response.body.data as TaskModel[];
        return taskList;
     }),
        catchError(err => throwError(() => err))
    );
  }

//   fetchTaskCategories(): Observable<any> {
//      return this.baseService.get('task-category').pipe(
//         catchError(error => {
//             console.error('Error fetching task categories:', error);
//             return throwError(error);
//         })
//     );
// }

  fetchTaskCategories(): Observable<any> {
    const requestParamModel = new RequestParamModel();
    requestParamModel.pageSize = 'all';

    return this.baseService.get('task-category', requestParamModel).pipe(
      catchError(error => {
        console.error('Error fetching task categories:', error);
        return throwError(error);
      })
    );
  }


  changeTaskStatus(requestModel: any) {
    if (requestModel.id) {
      return this.baseService.patch('task/' + requestModel.id, requestModel)
        .pipe(map<any, number>(response => {
            requestModel = response.data as TaskModel;
            return requestModel;
          }),
          catchError(err => throwError(() => err))
      );
    }
  }

  updateTask(data, taskId): Observable<number> {
    return this.baseService.patch('task/' + taskId, data)
      .pipe(map<any, any>(response => {
        return response;

     }),
        catchError(err => throwError(() => err))
    );
  }


}
