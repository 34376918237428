import { Injectable } from '@angular/core';
import { PaginationModel, RequestParamModel } from '../models';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../services';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FAQService {
  public API_CONSTANTS = "faq";
  public API_CONSTANTS_FOR_CMS = 'cms-page';
  public API_CONSTANTS_FOR_FAQ_CATEGORY = 'faq-category';
  public API_CONSTANTS_FOR_PRODUCT_COLLECTION = 'product-collection'
  constructor(
    public baseService: BaseService
  ) { }

  getCMSPage(requestParamModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANTS_FOR_CMS, requestParamModel).pipe(
      map<any, any>(response => {
        return response.body

        }),
        catchError(err => throwError(() => err))
    );
  }

  getFaqList(requestParaModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANTS, requestParaModel).pipe(
      map((response: any) => {
        const faqList = new PaginationModel<any>();
        const headers = response.headers;
        faqList.setHeaders(headers);
        faqList.data = response.body.data as any[];
        return faqList;
      }),
      catchError(err => throwError(() => err))
    );
  }

  postFaq(body: any) {
    return this.baseService.patch(`${this.API_CONSTANTS}/${body.id}`, body).pipe(
      map((response: any) => {
        return response.data;
         }),
        catchError(err => throwError(() => err))
    );
  }

  deletFaqCategory(FaqId: any) {
    return this.baseService.delete(`${this.API_CONSTANTS_FOR_FAQ_CATEGORY}/${FaqId}`).pipe
      (map((response: any) => {
        return response
      }),
      catchError(err => throwError(() => err))
    );
  }

  getFaqDetailsById(id: number, requestParaModel?: RequestParamModel) {
    return this.baseService.get(`${this.API_CONSTANTS}/${id}`, requestParaModel).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(err => throwError(() => err))
    );
  }

  getFaqCategoryList(requestParaModel?: RequestParamModel) {
    return this.baseService.get(this.API_CONSTANTS_FOR_FAQ_CATEGORY, requestParaModel).pipe(
      map((response: any) => {
        const faqCategoryList = new PaginationModel<any>();
        const headers = response.headers;
        faqCategoryList.setHeaders(headers);
        faqCategoryList.data = response.body.data as any[];
        return faqCategoryList;
      }),
      catchError(err => throwError(() => err))
    );
  }

  postFAQCategory(data: any) {
    if (data.id) {
      return this.baseService.patch(this.API_CONSTANTS_FOR_FAQ_CATEGORY + '/' + data.id, data).pipe(
        map((response: any) => {
          return response;
        }),
        catchError(err => throwError(() => err))
      );
    } else {
      return this.baseService.post(this.API_CONSTANTS_FOR_FAQ_CATEGORY, data).pipe(
        map((response: any) => {
          return response;
        }),
        catchError(err => throwError(() => err))
      );
    }

  }
  addUpdateFaq(body: any) {
    if (body.id) {
      return this.baseService.patch(`${this.API_CONSTANTS}/${body.id}`, body).pipe(
        map((response: any) => {
          return response.body;
        }),
        catchError(err => throwError(() => err))
      );
    } else {
      return this.baseService.uploadPost(`${this.API_CONSTANTS}`, body).pipe(
        map((response: any) => {
          return response.body;
        }),
        catchError(err => throwError(() => err))
      );
    }
  }

getCollectionList(requestParaModel?: RequestParamModel)
  {
    return this.baseService.get(this.API_CONSTANTS_FOR_PRODUCT_COLLECTION, requestParaModel).pipe(
      map((response: any) => {
        const collectionList = new PaginationModel<any>();
        const headers = response.headers;
        collectionList.setHeaders(headers);
        collectionList.data = response.body.data as any[];
        return collectionList;
      }),
      catchError(err => throwError(() => err))
    );

  }

  saveFaqVideo(body: any) {
    return this.baseService.uploadPost(`${this.API_CONSTANTS}/upload`, body).pipe(
      map((response: any) => {
        return response
      }),
      catchError(err => throwError(() => err))
    );
  }

  deleteFaqVideo(faqId: any, field: string) {
    const url = `${this.API_CONSTANTS}/delete-video/${faqId}?field=${field}`;

    return this.baseService.delete(url).pipe(
      map((response: any) => {
        return response
      }),
      catchError(err => throwError(() => err))
    );
  }

  deletFAQ(FaqId: any) {
    return this.baseService.delete(`${this.API_CONSTANTS}/${FaqId}`).pipe
      (map((response: any) => {
        return response
      }),
      catchError(err => throwError(() => err))
    );
  }

//  getFaqCategoryList(requestParamModel?: RequestParamModel) {
//     return this.baseService.get(this.API_CONSTANTS_FOR_FAQ_CATEGORY, requestParamModel).pipe(
//       map((response: any) => {
//         return response.body
//       }, (error: any) => {
//         return error;
//       })
//     )
//   }


}
