import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { DashboardParentCount, PaginationModel, QuoteOrderSampleModel, RequestParamModel } from '../models';
import { BaseService } from '../services';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private baseService: BaseService) { }

  /**
   * GET DASHBOARD STATS COUNT
   */
  getParentCounts(requestParamModel: RequestParamModel): any {
    return this.baseService.get('dashboard', requestParamModel)
      .pipe(map<any, any>(response => {
        const data = response.body.data as DashboardParentCount;
        return data;
      }),
      catchError(err => throwError(() => err))
  );
  }
    /**
   * DONATE PAYABLE COMMITMENT
   */
    donateCommitmentPayable(): any {
      return this.baseService.post('dashboard/pay-commitment-payable', {}).pipe(
        map<any, any>(response => {
          return response;
          }),
        catchError(err => throwError(() => err))
    );
    }


  getRevenueData(requestParamModel: RequestParamModel)
  {
    return this.baseService.get('dashboard/revenue-chart-data', requestParamModel)
    .pipe(map<any, any>(response => {
      const data = response.body.data as DashboardParentCount;
      return data;
      }),
    catchError(err => throwError(() => err))
);
  }

  getDeviceData(requestParamModel: RequestParamModel)
  {
    return this.baseService.get('dashboard/device-chart-data', requestParamModel)
    .pipe(map<any, any>(response => {
      const data = response.body.data as DashboardParentCount;
      return data;
      }),
    catchError(err => throwError(() => err))
);
  }

  getAccountFiguresData(requestParamModel: RequestParamModel)
  {
    return this.baseService.get('dashboard/account-figure-chart-data', requestParamModel)
    .pipe(map<any, any>(response => {
      const data = response.body.data as DashboardParentCount;
      return data;
      }),
    catchError(err => throwError(() => err))
);
  }



  getPopularProductsData(requestParamModel: RequestParamModel): any {
    return this.baseService.get('product', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<QuoteOrderSampleModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as QuoteOrderSampleModel[];
        return accountList;
      }),
      catchError(err => throwError(() => err))
  );
  }




getQuotesData(requestParamModel: RequestParamModel)
  {
    return this.baseService.get('dashboard/quotes-chart-data', requestParamModel)
    .pipe(map<any, any>(response => {
      const data = response.body.data as DashboardParentCount;
      return data;
    }),
    catchError(err => throwError(() => err))
);
  }

  getPerformanceFiguresData(requestParamModel: RequestParamModel)
  {
    return this.baseService.get('dashboard/performance-figure-chart-data', requestParamModel)
    .pipe(map<any, any>(response => {
      const data = response.body.data as DashboardParentCount;
      return data;
    }),
    catchError(err => throwError(() => err))
);
  }

  getRegisteredUserFiguresData(requestParamModel: RequestParamModel)
  {
    return this.baseService.get('dashboard/registered-user-figure-chart-data', requestParamModel)
    .pipe(map<any, any>(response => {
      const data = response.body.data as DashboardParentCount;
      return data;
    }),
    catchError(err => throwError(() => err))
);
  }

  getSessionFiguresData(requestParamModel: RequestParamModel)
  {
    return this.baseService.get('dashboard/session-figure-chart-data', requestParamModel)
    .pipe(map<any, any>(response => {
      const data = response.body.data as DashboardParentCount;
      return data;
    }),
    catchError(err => throwError(() => err))
);
  }

  getBounceRateFiguresData(requestParamModel: RequestParamModel)
  {
    return this.baseService.get('dashboard/bounce-rate-figure-chart-data', requestParamModel)
    .pipe(map<any, any>(response => {
      const data = response.body.data as DashboardParentCount;
      return data;
    }),
    catchError(err => throwError(() => err))
);
  }

  getTopLocationByRegionsData(requestParamModel: RequestParamModel)
  {
    return this.baseService.get('dashboard/top-location-by-region-chart-data', requestParamModel)
    .pipe(map<any, any>(response => {
      const data = response.body.data as DashboardParentCount;
      return data;
      }),
    catchError(err => throwError(() => err))
);
  }

  getTopPerformersByContactData(requestParamModel: RequestParamModel): any {
    return this.baseService.get('contact', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<QuoteOrderSampleModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as QuoteOrderSampleModel[];
        return accountList;
      }),
      catchError(err => throwError(() => err))
  );
  }

  getTopPerformersByAccountData(requestParamModel: RequestParamModel): any {
    return this.baseService.get('account', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<QuoteOrderSampleModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as QuoteOrderSampleModel[];
        return accountList;
      }),
      catchError(err => throwError(() => err))
  );
  }

  getAverageOrderDeliveredTimeChartData(requestParamModel: RequestParamModel): any {
    return this.baseService.get('dashboard/avg-order-delivered-time-chart-data', requestParamModel)
    .pipe(map<any, any>(response => {
      const data = response.body.data as any;
      return data;
      }),
    catchError(err => throwError(() => err))
);
  }

  getAvergeOrderDeliveredTimeListData(requestParamModel: RequestParamModel): any {
     return this.baseService.get('quote-order', requestParamModel)
        .pipe(map<any, any>(response => {
          const responseList = new PaginationModel<any>();
          const headers = response.headers;
          responseList.setHeaders(headers);
          responseList.data = response.body.data as any[];
          return responseList;
           }),
        catchError(err => throwError(() => err))
    );
    }
}
