import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmailTemplateModel, PaginationModel, RequestParamModel } from '../models';
import { BaseService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class EmailTemplateService {

    constructor(private baseService: BaseService) { }

    getEmailTemplates(requestParamModel: RequestParamModel): any {
        return this.baseService.get('email-template', requestParamModel)
            .pipe(map<any, any>(response => {
                const templateLists = new PaginationModel<EmailTemplateModel>();
                const headers = response.headers;
                templateLists.setHeaders(headers);
                templateLists.data = response.body.data as EmailTemplateModel[];
                return templateLists;

        }),
        catchError(err => throwError(() => err))
    );
    }

    // get data by ID.
    getEmailTemplatedetail(id: number, requestParamModel: RequestParamModel): Observable<EmailTemplateModel> {
        return this.baseService.get('email-template/' + id, requestParamModel)
            .pipe(map<any, EmailTemplateModel>(response => {
                return response.body.data as EmailTemplateModel;

        }),
        catchError(err => throwError(() => err))
    );
    }



    // get data by ID.
    save(emailTemplateModel: EmailTemplateModel): Observable<number> {
        return this.baseService.patch('email-template/' + emailTemplateModel.id, emailTemplateModel)
            .pipe(map<any, number>(response => {
                const bodydata = response.data as EmailTemplateModel;
                return bodydata.id;
                  }),
              catchError(err => throwError(() => err))
          );
    }

    sendMail(mailContent: any): Observable<any> {
        return this.baseService.post('email-template/send-mail', mailContent).pipe(
            map<any, any>(response => {
                return response.data
              }),
              catchError(err => throwError(() => err))
          );
    }

    // get data by ID.
    getEmailTemplateDetailWithParam(param: any): Observable<EmailTemplateModel> {
        return this.baseService.get('email-template/' + param)
            .pipe(map<any, EmailTemplateModel>(response => {
                return response.body.data as EmailTemplateModel;

        }),
        catchError(err => throwError(() => err))
    );
    }
}
